<!--explain：;@author: cy; @version: 1.0; date: 2022/4/22;-->
<template>
  <v-breadcrumbs style="padding-left: 0" :items="getBreadcrumb">
    <template #item="{ item }">
      <v-breadcrumbs-item :disabled="item.disabled" :href="item.path">
        {{ item.name }}
      </v-breadcrumbs-item>
    </template>
  </v-breadcrumbs>
</template>

<script>
export default {
  name: 'RzBreadcrumbs',
  data() {
    return {};
  },
  computed: {
    getBreadcrumb() {
      let arr = [];
      const currPath = this.$route.path;
      const menuList = this.accountState.roleVos[0].routeVos;
      const pathList = menuList.map((item) => item.path);
      const index = pathList.indexOf(currPath);
      const item = menuList[index];
      item && arr.push(item);
      const parentItem = menuList.filter((val) => {
        if (item.parentUuid === val.uuid) {
          val.disabled = true;
          return val;
        }
      });
      arr = parentItem.concat(arr);
      arr;
      return arr;
    },
  },
};
</script>

<style lang="scss" scoped></style>
